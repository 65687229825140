import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './styles/utilities.css'
import DiagramsPage from "./pages/diagrams-page/DiagramsPage";
import StatisticsPage from "./pages/statistics-page/StatisticsPage";
import RequestManagementPage from "./pages/request-managament-page/RequestManagementPage";

const router = createBrowserRouter([
  {
    path: '/portal/administracion/civica-transaccional/diagramas-nfc',
    element: <DiagramsPage />
  },
  {
    path: '/portal/administracion/civica-transaccional/estadisticas',
    element: <StatisticsPage />
  },
  {
    path: '/portal/administracion/civica-transaccional/gestion-solicitudes',
    element: <RequestManagementPage />
  }
])

export default function Root(props) {
  return(
    <>
      <RouterProvider 
        router={router}
      />
    </>
  );
}
