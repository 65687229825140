


const RequestManagementPage = () => {
    return(
        <main className="container-padding">
            <h1>Gestión Solicitudes</h1>
        </main>
    )
}

export default RequestManagementPage;