// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.container-padding{
    padding: 0 2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/utilities.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;AACnB","sourcesContent":["\r\n\r\n.container-padding{\r\n    padding: 0 2rem;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
