

const StatisticsPage = () => {
    return(
        <main className="container-padding">
            <h1>Estadísticas</h1>
        </main>
    );
}

export default StatisticsPage;