import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { StateTest } from '@metro/civica-styleguide';


const DiagramsPage = () => {
    return(
        <main
            className="container-padding"
        >
            <h1>Diagramas NFC 2</h1>
            <StateTest />
            <Link
                to="/portal/administracion/registros/correos"
            >Correos</Link>
            <Link
                to="/portal/administracion/registros/festivos"
            >Festivos</Link>
            <p>Nam euismod est nisl. Suspendisse eget justo lectus. Phasellus imperdiet pretium neque id efficitur. Proin viverra, nulla eget tincidunt rutrum, nulla ex convallis leo, at pellentesque tellus tortor sit amet turpis. Vivamus ante risus, mollis sed fermentum aliquet, tempus eget purus. Aliquam vel nisl aliquam, condimentum sem sed, dictum elit. Praesent auctor placerat scelerisque. Pellentesque lobortis nulla lacus, sit amet elementum mi condimentum sed. Vivamus fermentum est nisl, ut consectetur dolor dignissim at. Aliquam hendrerit erat leo, id pulvinar ligula finibus ac. Proin eu sapien et ex venenatis tempus. Quisque at aliquam orci. Morbi convallis finibus turpis, vel lobortis diam aliquet eu. Ut faucibus quam in libero pellentesque, ut tristique nisi interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Cras lobortis, ipsum eu tempus sollicitudin, justo arcu aliquet massa, et euismod odio sem vel orci. Sed pretium nec arcu eget egestas. Cras sollicitudin euismod arcu, eget accumsan purus tempus a. Vestibulum tempus tristique turpis porta pretium. Aenean et dictum dolor. Donec non fringilla est. Cras condimentum libero at ipsum efficitur dictum. Nulla facilisi. Aliquam eleifend faucibus scelerisque. Mauris vehicula, magna eget lacinia venenatis, ligula leo egestas quam, id auctor elit tellus id magna. Donec nec enim ac sem lacinia accumsan. Nunc vel ligula felis. Donec fermentum eros at vehicula mollis.

Morbi viverra luctus euismod. Cras ac ornare neque, id tincidunt nisl. Morbi quis imperdiet nunc. Donec vel turpis urna. Proin ac erat at felis pulvinar ultrices sed sit amet massa. Aliquam erat volutpat. Phasellus vitae bibendum tortor. Etiam nisl nisl, mattis vel ultrices suscipit, tempus eu nibh. Etiam rutrum elit eu augue sodales, ac efficitur elit faucibus. In scelerisque varius leo, tincidunt aliquam enim luctus fringilla. Nullam quis quam vel dui posuere vestibulum ut elementum erat. Fusce condimentum, est sit amet pulvinar faucibus, quam odio consectetur mauris, sit amet auctor elit justo sed est. Proin pharetra velit sed odio tincidunt, vitae facilisis nulla efficitur.

Fusce neque ante, euismod vitae velit sit amet, ultricies laoreet augue. Donec facilisis maximus consequat. Morbi dapibus lorem nec quam convallis rutrum. Proin eget consectetur orci. Duis erat leo, pulvinar a dapibus eu, lacinia quis justo. Nulla feugiat urna quis lacinia tempus. Proin massa massa, ultricies at nisl at, convallis pharetra metus. Donec et urna risus. Fusce mattis blandit augue, vel tristique est volutpat a. Phasellus rhoncus eros felis, vulputate blandit lorem facilisis id. Praesent eu mauris non libero maximus venenatis. Donec eleifend pharetra eleifend. Integer ultrices eros mauris, nec placerat sem tempus in.

Donec gravida pellentesque diam, eget hendrerit quam. Duis hendrerit suscipit sapien, sed interdum tortor aliquet at. Duis non magna ac lectus ultricies vestibulum. Vestibulum ultricies dignissim ipsum ut tristique. Duis iaculis semper neque in porttitor. Phasellus rhoncus lorem ligula, finibus efficitur urna tempor at. Donec scelerisque imperdiet tincidunt. Aliquam laoreet vel nulla at elementum. Pellentesque vitae massa sodales, mollis dui eu, sodales tellus.</p>
<p>Nam euismod est nisl. Suspendisse eget justo lectus. Phasellus imperdiet pretium neque id efficitur. Proin viverra, nulla eget tincidunt rutrum, nulla ex convallis leo, at pellentesque tellus tortor sit amet turpis. Vivamus ante risus, mollis sed fermentum aliquet, tempus eget purus. Aliquam vel nisl aliquam, condimentum sem sed, dictum elit. Praesent auctor placerat scelerisque. Pellentesque lobortis nulla lacus, sit amet elementum mi condimentum sed. Vivamus fermentum est nisl, ut consectetur dolor dignissim at. Aliquam hendrerit erat leo, id pulvinar ligula finibus ac. Proin eu sapien et ex venenatis tempus. Quisque at aliquam orci. Morbi convallis finibus turpis, vel lobortis diam aliquet eu. Ut faucibus quam in libero pellentesque, ut tristique nisi interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Cras lobortis, ipsum eu tempus sollicitudin, justo arcu aliquet massa, et euismod odio sem vel orci. Sed pretium nec arcu eget egestas. Cras sollicitudin euismod arcu, eget accumsan purus tempus a. Vestibulum tempus tristique turpis porta pretium. Aenean et dictum dolor. Donec non fringilla est. Cras condimentum libero at ipsum efficitur dictum. Nulla facilisi. Aliquam eleifend faucibus scelerisque. Mauris vehicula, magna eget lacinia venenatis, ligula leo egestas quam, id auctor elit tellus id magna. Donec nec enim ac sem lacinia accumsan. Nunc vel ligula felis. Donec fermentum eros at vehicula mollis.

Morbi viverra luctus euismod. Cras ac ornare neque, id tincidunt nisl. Morbi quis imperdiet nunc. Donec vel turpis urna. Proin ac erat at felis pulvinar ultrices sed sit amet massa. Aliquam erat volutpat. Phasellus vitae bibendum tortor. Etiam nisl nisl, mattis vel ultrices suscipit, tempus eu nibh. Etiam rutrum elit eu augue sodales, ac efficitur elit faucibus. In scelerisque varius leo, tincidunt aliquam enim luctus fringilla. Nullam quis quam vel dui posuere vestibulum ut elementum erat. Fusce condimentum, est sit amet pulvinar faucibus, quam odio consectetur mauris, sit amet auctor elit justo sed est. Proin pharetra velit sed odio tincidunt, vitae facilisis nulla efficitur.

Fusce neque ante, euismod vitae velit sit amet, ultricies laoreet augue. Donec facilisis maximus consequat. Morbi dapibus lorem nec quam convallis rutrum. Proin eget consectetur orci. Duis erat leo, pulvinar a dapibus eu, lacinia quis justo. Nulla feugiat urna quis lacinia tempus. Proin massa massa, ultricies at nisl at, convallis pharetra metus. Donec et urna risus. Fusce mattis blandit augue, vel tristique est volutpat a. Phasellus rhoncus eros felis, vulputate blandit lorem facilisis id. Praesent eu mauris non libero maximus venenatis. Donec eleifend pharetra eleifend. Integer ultrices eros mauris, nec placerat sem tempus in.

Donec gravida pellentesque diam, eget hendrerit quam. Duis hendrerit suscipit sapien, sed interdum tortor aliquet at. Duis non magna ac lectus ultricies vestibulum. Vestibulum ultricies dignissim ipsum ut tristique. Duis iaculis semper neque in porttitor. Phasellus rhoncus lorem ligula, finibus efficitur urna tempor at. Donec scelerisque imperdiet tincidunt. Aliquam laoreet vel nulla at elementum. Pellentesque vitae massa sodales, mollis dui eu, sodales tellus.</p>
<p>Nam euismod est nisl. Suspendisse eget justo lectus. Phasellus imperdiet pretium neque id efficitur. Proin viverra, nulla eget tincidunt rutrum, nulla ex convallis leo, at pellentesque tellus tortor sit amet turpis. Vivamus ante risus, mollis sed fermentum aliquet, tempus eget purus. Aliquam vel nisl aliquam, condimentum sem sed, dictum elit. Praesent auctor placerat scelerisque. Pellentesque lobortis nulla lacus, sit amet elementum mi condimentum sed. Vivamus fermentum est nisl, ut consectetur dolor dignissim at. Aliquam hendrerit erat leo, id pulvinar ligula finibus ac. Proin eu sapien et ex venenatis tempus. Quisque at aliquam orci. Morbi convallis finibus turpis, vel lobortis diam aliquet eu. Ut faucibus quam in libero pellentesque, ut tristique nisi interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Cras lobortis, ipsum eu tempus sollicitudin, justo arcu aliquet massa, et euismod odio sem vel orci. Sed pretium nec arcu eget egestas. Cras sollicitudin euismod arcu, eget accumsan purus tempus a. Vestibulum tempus tristique turpis porta pretium. Aenean et dictum dolor. Donec non fringilla est. Cras condimentum libero at ipsum efficitur dictum. Nulla facilisi. Aliquam eleifend faucibus scelerisque. Mauris vehicula, magna eget lacinia venenatis, ligula leo egestas quam, id auctor elit tellus id magna. Donec nec enim ac sem lacinia accumsan. Nunc vel ligula felis. Donec fermentum eros at vehicula mollis.

Morbi viverra luctus euismod. Cras ac ornare neque, id tincidunt nisl. Morbi quis imperdiet nunc. Donec vel turpis urna. Proin ac erat at felis pulvinar ultrices sed sit amet massa. Aliquam erat volutpat. Phasellus vitae bibendum tortor. Etiam nisl nisl, mattis vel ultrices suscipit, tempus eu nibh. Etiam rutrum elit eu augue sodales, ac efficitur elit faucibus. In scelerisque varius leo, tincidunt aliquam enim luctus fringilla. Nullam quis quam vel dui posuere vestibulum ut elementum erat. Fusce condimentum, est sit amet pulvinar faucibus, quam odio consectetur mauris, sit amet auctor elit justo sed est. Proin pharetra velit sed odio tincidunt, vitae facilisis nulla efficitur.

Fusce neque ante, euismod vitae velit sit amet, ultricies laoreet augue. Donec facilisis maximus consequat. Morbi dapibus lorem nec quam convallis rutrum. Proin eget consectetur orci. Duis erat leo, pulvinar a dapibus eu, lacinia quis justo. Nulla feugiat urna quis lacinia tempus. Proin massa massa, ultricies at nisl at, convallis pharetra metus. Donec et urna risus. Fusce mattis blandit augue, vel tristique est volutpat a. Phasellus rhoncus eros felis, vulputate blandit lorem facilisis id. Praesent eu mauris non libero maximus venenatis. Donec eleifend pharetra eleifend. Integer ultrices eros mauris, nec placerat sem tempus in.

Donec gravida pellentesque diam, eget hendrerit quam. Duis hendrerit suscipit sapien, sed interdum tortor aliquet at. Duis non magna ac lectus ultricies vestibulum. Vestibulum ultricies dignissim ipsum ut tristique. Duis iaculis semper neque in porttitor. Phasellus rhoncus lorem ligula, finibus efficitur urna tempor at. Donec scelerisque imperdiet tincidunt. Aliquam laoreet vel nulla at elementum. Pellentesque vitae massa sodales, mollis dui eu, sodales tellus.</p>
<p>Nam euismod est nisl. Suspendisse eget justo lectus. Phasellus imperdiet pretium neque id efficitur. Proin viverra, nulla eget tincidunt rutrum, nulla ex convallis leo, at pellentesque tellus tortor sit amet turpis. Vivamus ante risus, mollis sed fermentum aliquet, tempus eget purus. Aliquam vel nisl aliquam, condimentum sem sed, dictum elit. Praesent auctor placerat scelerisque. Pellentesque lobortis nulla lacus, sit amet elementum mi condimentum sed. Vivamus fermentum est nisl, ut consectetur dolor dignissim at. Aliquam hendrerit erat leo, id pulvinar ligula finibus ac. Proin eu sapien et ex venenatis tempus. Quisque at aliquam orci. Morbi convallis finibus turpis, vel lobortis diam aliquet eu. Ut faucibus quam in libero pellentesque, ut tristique nisi interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Cras lobortis, ipsum eu tempus sollicitudin, justo arcu aliquet massa, et euismod odio sem vel orci. Sed pretium nec arcu eget egestas. Cras sollicitudin euismod arcu, eget accumsan purus tempus a. Vestibulum tempus tristique turpis porta pretium. Aenean et dictum dolor. Donec non fringilla est. Cras condimentum libero at ipsum efficitur dictum. Nulla facilisi. Aliquam eleifend faucibus scelerisque. Mauris vehicula, magna eget lacinia venenatis, ligula leo egestas quam, id auctor elit tellus id magna. Donec nec enim ac sem lacinia accumsan. Nunc vel ligula felis. Donec fermentum eros at vehicula mollis.

Morbi viverra luctus euismod. Cras ac ornare neque, id tincidunt nisl. Morbi quis imperdiet nunc. Donec vel turpis urna. Proin ac erat at felis pulvinar ultrices sed sit amet massa. Aliquam erat volutpat. Phasellus vitae bibendum tortor. Etiam nisl nisl, mattis vel ultrices suscipit, tempus eu nibh. Etiam rutrum elit eu augue sodales, ac efficitur elit faucibus. In scelerisque varius leo, tincidunt aliquam enim luctus fringilla. Nullam quis quam vel dui posuere vestibulum ut elementum erat. Fusce condimentum, est sit amet pulvinar faucibus, quam odio consectetur mauris, sit amet auctor elit justo sed est. Proin pharetra velit sed odio tincidunt, vitae facilisis nulla efficitur.

Fusce neque ante, euismod vitae velit sit amet, ultricies laoreet augue. Donec facilisis maximus consequat. Morbi dapibus lorem nec quam convallis rutrum. Proin eget consectetur orci. Duis erat leo, pulvinar a dapibus eu, lacinia quis justo. Nulla feugiat urna quis lacinia tempus. Proin massa massa, ultricies at nisl at, convallis pharetra metus. Donec et urna risus. Fusce mattis blandit augue, vel tristique est volutpat a. Phasellus rhoncus eros felis, vulputate blandit lorem facilisis id. Praesent eu mauris non libero maximus venenatis. Donec eleifend pharetra eleifend. Integer ultrices eros mauris, nec placerat sem tempus in.

Donec gravida pellentesque diam, eget hendrerit quam. Duis hendrerit suscipit sapien, sed interdum tortor aliquet at. Duis non magna ac lectus ultricies vestibulum. Vestibulum ultricies dignissim ipsum ut tristique. Duis iaculis semper neque in porttitor. Phasellus rhoncus lorem ligula, finibus efficitur urna tempor at. Donec scelerisque imperdiet tincidunt. Aliquam laoreet vel nulla at elementum. Pellentesque vitae massa sodales, mollis dui eu, sodales tellus.</p>
<p>Nam euismod est nisl. Suspendisse eget justo lectus. Phasellus imperdiet pretium neque id efficitur. Proin viverra, nulla eget tincidunt rutrum, nulla ex convallis leo, at pellentesque tellus tortor sit amet turpis. Vivamus ante risus, mollis sed fermentum aliquet, tempus eget purus. Aliquam vel nisl aliquam, condimentum sem sed, dictum elit. Praesent auctor placerat scelerisque. Pellentesque lobortis nulla lacus, sit amet elementum mi condimentum sed. Vivamus fermentum est nisl, ut consectetur dolor dignissim at. Aliquam hendrerit erat leo, id pulvinar ligula finibus ac. Proin eu sapien et ex venenatis tempus. Quisque at aliquam orci. Morbi convallis finibus turpis, vel lobortis diam aliquet eu. Ut faucibus quam in libero pellentesque, ut tristique nisi interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Cras lobortis, ipsum eu tempus sollicitudin, justo arcu aliquet massa, et euismod odio sem vel orci. Sed pretium nec arcu eget egestas. Cras sollicitudin euismod arcu, eget accumsan purus tempus a. Vestibulum tempus tristique turpis porta pretium. Aenean et dictum dolor. Donec non fringilla est. Cras condimentum libero at ipsum efficitur dictum. Nulla facilisi. Aliquam eleifend faucibus scelerisque. Mauris vehicula, magna eget lacinia venenatis, ligula leo egestas quam, id auctor elit tellus id magna. Donec nec enim ac sem lacinia accumsan. Nunc vel ligula felis. Donec fermentum eros at vehicula mollis.

Morbi viverra luctus euismod. Cras ac ornare neque, id tincidunt nisl. Morbi quis imperdiet nunc. Donec vel turpis urna. Proin ac erat at felis pulvinar ultrices sed sit amet massa. Aliquam erat volutpat. Phasellus vitae bibendum tortor. Etiam nisl nisl, mattis vel ultrices suscipit, tempus eu nibh. Etiam rutrum elit eu augue sodales, ac efficitur elit faucibus. In scelerisque varius leo, tincidunt aliquam enim luctus fringilla. Nullam quis quam vel dui posuere vestibulum ut elementum erat. Fusce condimentum, est sit amet pulvinar faucibus, quam odio consectetur mauris, sit amet auctor elit justo sed est. Proin pharetra velit sed odio tincidunt, vitae facilisis nulla efficitur.

Fusce neque ante, euismod vitae velit sit amet, ultricies laoreet augue. Donec facilisis maximus consequat. Morbi dapibus lorem nec quam convallis rutrum. Proin eget consectetur orci. Duis erat leo, pulvinar a dapibus eu, lacinia quis justo. Nulla feugiat urna quis lacinia tempus. Proin massa massa, ultricies at nisl at, convallis pharetra metus. Donec et urna risus. Fusce mattis blandit augue, vel tristique est volutpat a. Phasellus rhoncus eros felis, vulputate blandit lorem facilisis id. Praesent eu mauris non libero maximus venenatis. Donec eleifend pharetra eleifend. Integer ultrices eros mauris, nec placerat sem tempus in.

Donec gravida pellentesque diam, eget hendrerit quam. Duis hendrerit suscipit sapien, sed interdum tortor aliquet at. Duis non magna ac lectus ultricies vestibulum. Vestibulum ultricies dignissim ipsum ut tristique. Duis iaculis semper neque in porttitor. Phasellus rhoncus lorem ligula, finibus efficitur urna tempor at. Donec scelerisque imperdiet tincidunt. Aliquam laoreet vel nulla at elementum. Pellentesque vitae massa sodales, mollis dui eu, sodales tellus.</p>
        </main>
    );  
}

export default DiagramsPage;